import Container from 'components/Container'
import CustomerInfoFetcher from 'components/CustomerInfoFetcher'
import LoginForm from 'components/auth/LoginForm'
import ResetPasswordForm from 'components/auth/ResetPasswordForm'
import withIsLoggedIn from 'components/hocs/withIsLoggedIn'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

function LoginPage() {
  const [formName, setFormName] = useState('login') // "login" || "reset"
  return (
    <CustomerInfoFetcher>
      <Container css={{ position: 'relative' }}>
        <Helmet title='Login'>
          <meta name='robots' content='noindex' />
        </Helmet>
        {formName === 'login' && <LoginForm openRePassword={() => setFormName('reset')} />}
        {formName === 'reset' && (
          <ResetPasswordForm cancelRePassword={() => setFormName('login')} />
        )}
      </Container>
    </CustomerInfoFetcher>
  )
}
export default withIsLoggedIn(LoginPage)
