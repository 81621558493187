import React from 'react'
import theme from 'styles/theme'

export default function FormButton({ icon, children, handleClick, disabled, ...rest }) {
  return (
    <button
      css={{
        color: '#fff',
        fontSize: '1rem',
        backgroundColor: theme.colors.spinachDark,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '1rem',
        outline: 'none',
        border: 'none',
        borderRadius: '0.2rem',
        cursor: 'pointer',
        opacity: disabled ? 0.5 : 1,
      }}
      disabled={disabled}
      onClick={handleClick}
      {...rest}
    >
      <span>{children}</span>
      <span css={{ marginLeft: '.5rem' }}>{icon}</span>
    </button>
  )
}
