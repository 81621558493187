import { useMutation } from '@apollo/client'
import { RECOVER_PASSWORD_EMAIL } from 'lib/graphql/auth'
import React, { useState } from 'react'

import FormButton from '../FormButton'
import Icon from '../Icon'
import Input from '../forms/Input'
import Label from '../forms/Label'

export default function ResetPasswordForm({ cancelRePassword }) {
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState([])
  const [recoverWithEmail, { loading, error, data }] = useMutation(RECOVER_PASSWORD_EMAIL, {
    onCompleted: data => {
      if (data?.customerRecover?.customerUserErrors?.length > 0)
        setErrors(data.customerRecover.customerUserErrors)
      else cancelRePassword()
    },
    onError: error => {
      console.log(error)
    },
  })

  const handleChange = e => {
    setEmail(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setErrors([])
    recoverWithEmail({
      variables: {
        email,
      },
    })
  }

  return (
    <div
      css={{
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '36rem',
        width: '100%',
        minHeight: '80vh',
        padding: '5rem 0',
      }}
    >
      <form
        onSubmit={handleSubmit}
        css={{
          width: '100%',
          backgroundColor: '#fff',
          borderRadius: '0.5rem',
          height: 'auto',
          padding: '3rem',
        }}
      >
        <h3 css={{ fontSize: '3rem', textAlign: 'center' }}>Reset your password</h3>
        <p css={{ textAlign: 'center' }}>We will send you an email to reset your password</p>
        <div css={{ margin: '2rem 0' }}>
          <Label for='email'>Email</Label>
          <Input
            name='email'
            placeholder='Enter your email'
            value={email}
            onChange={handleChange}
          />
        </div>
        <FormButton
          disabled={loading}
          type='submit'
          icon={<Icon.ArrowForward width={16} height={16} />}
          onClick={handleSubmit}
        >
          Submit
        </FormButton>
        {errors?.map((e, i) => (
          <p key={i} css={{ color: 'red', textAlign: 'center' }}>
            {e.message}
          </p>
        ))}
        <div css={{ textAlign: 'center', marginTop: '3rem' }}>
          <div
            onClick={cancelRePassword}
            css={{
              textDecoration: 'underline',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
          >
            Cancel
          </div>
        </div>
      </form>
    </div>
  )
}
