import { gql } from '@apollo/client'

export const GET_CUSTOMER = gql`
  query getCustomer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      firstName
      lastName
      acceptsMarketing
      email
      phone
      defaultAddress {
        id
        address1
        address2
        city
        company
        country
        countryCodeV2
        province
        provinceCode
        zip
      }
    }
  }
`

export const LOG_IN = gql`
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        message
        field
      }
    }
  }
`

export const SIGN_UP = gql`
  mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        acceptsMarketing
        displayName
        email
        firstName
        lastName
        phone
      }
      customerUserErrors {
        code
        message
        field
      }
    }
  }
`

export const RECOVER_PASSWORD_EMAIL = gql`
  mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        code
        message
        field
      }
    }
  }
`

export const RECOVER_PASSWORD = gql`
  mutation customerReset($id: ID!, $input: CustomerResetInput!) {
    customerReset(id: $id, input: $input) {
      customer {
        acceptsMarketing
        displayName
        email
        firstName
        lastName
        phone
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        message
        field
      }
    }
  }
`
