import { useLazyQuery, useMutation } from '@apollo/client'
import Link from 'components/Link'
import { navigate } from 'gatsby'
import { GET_CUSTOMER, LOG_IN } from 'lib/graphql/auth'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { authActions } from 'store/auth/slice'
import theme from 'styles/theme'
import { setAccessToken } from 'utils/auth'

import FormButton from '../FormButton'
import Icon from '../Icon'
import Input from '../forms/Input'
import Label from '../forms/Label'

export default function LoginForm({ openRePassword }) {
  const dispatch = useDispatch()
  const [logInPayload, setLogInPayload] = useState({
    email: '',
    password: '',
  })
  const [errors, setErrors] = useState([])
  const { email, password } = logInPayload

  const [getUser] = useLazyQuery(GET_CUSTOMER, {
    onCompleted: data => {
      if (data.customer) {
        dispatch(authActions.setUser(data.customer))
        dispatch(authActions.setLoggedInUser())
        navigate('/my-account')
      }
    },
  })

  const [logIn, { loading, error, data }] = useMutation(LOG_IN, {
    onCompleted: data => {
      if (data.customerAccessTokenCreate.customerUserErrors.length > 0) {
        return setErrors(data.customerAccessTokenCreate.customerUserErrors)
      }
      setAccessToken(data.customerAccessTokenCreate.customerAccessToken.accessToken)
      getUser({
        variables: {
          customerAccessToken: data.customerAccessTokenCreate.customerAccessToken.accessToken,
        },
      })
    },
    onError: error => {
      console.log(error)
    },
  })

  const handleChange = e => {
    setLogInPayload({
      ...logInPayload,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    logIn({
      variables: {
        input: logInPayload,
      },
    })
  }

  return (
    <div
      css={{
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '36rem',
        width: '100%',
        minHeight: '80vh',
        padding: '5rem 0',
      }}
    >
      <form
        onSubmit={handleSubmit}
        css={{
          width: '100%',
          backgroundColor: '#fff',
          borderRadius: '0.5rem',
          height: 'auto',
          padding: '3rem',
        }}
      >
        <h3 css={{ fontSize: '3rem', textAlign: 'center' }}>Login</h3>

        <div css={{ margin: '2rem 0' }}>
          <Label for='email'>Email</Label>
          <Input
            type='email'
            name='email'
            placeholder='Enter your email'
            value={email}
            onChange={handleChange}
          />
        </div>
        <div css={{ margin: '2rem 0' }}>
          <div css={{ display: 'flex', justifyContent: 'space-between' }}>
            <Label for='password'>Password</Label>
            <div
              onClick={openRePassword}
              css={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              Forgot password?
            </div>
          </div>
          <Input
            type='password'
            name='password'
            value={password}
            placeholder='Enter your password'
            onChange={handleChange}
          />
        </div>
        {errors?.map((e, i) => (
          <p key={i} css={{ color: 'red', textAlign: 'center' }}>
            {e.message}
          </p>
        ))}
        <FormButton
          handleClick={handleSubmit}
          disabled={loading}
          icon={<Icon.ArrowForward width={16} height={16} />}
        >
          Sign in
        </FormButton>

        <h6
          href='#'
          css={{
            marginTop: '1rem',
            color: theme.colors.spinachDark,
            textAlign: 'center',
          }}
        >
          Don't have an account?{' '}
          <Link css={{ textDecoration: 'underline' }} to='/sign-up'>
            Sign up for free
          </Link>
        </h6>

        <div css={{ textAlign: 'center', marginTop: '3rem' }}>
          <a
            href='https://account.liveowyn.com/tools/recurring/get-subscription-access'
            css={{ textDecoration: 'underline', fontWeight: 'bold' }}
          >
            Having issues managing your subscriptions?
          </a>
        </div>
      </form>
    </div>
  )
}
